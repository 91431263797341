import { combineReducers } from "redux";
import candidate from "./Candidate/index";
import { states, districts, subDistricts, townVillages } from "./location/index";
import { translationData } from "./translation";
import farmerListings from "./kopia/farmerListings";
export default combineReducers({
    candidate,
    states,
    districts,
    subDistricts,
    townVillages,
    translationData,
    farmerListings,
});
