export function states(state = [], action) {
    if (action.type === "FETCH_STATE") {
        return action.payload;
    }
    return state;
}

export function districts(state = [], action) {
    if (action.type === "FETCH_DISTRICTS") {
        return action.payload;
    }
    return state;
}

export function subDistricts(state = [], action) {
    if (action.type === "FETCH_SUB_DISTRICTS") {
        return action.payload;
    }
    return state;
}

export function townVillages(state = [], action) {
    if (action.type === "FETCH_TOWN_VILLAGE") {
        return action.payload;
    }
    return state;
}

