// utility functions will go here

class Utility {
  getCurrentYear() {
    var year = 2018;
    try {
      year = new Date().getFullYear();
    } catch (e) {
      year = 2018;
    }
    return year;
  }
}

export default new Utility();
