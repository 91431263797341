import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//import './scss/bootstrap.scss';
import "./scss/index.scss";
import Home from "./components/pages/Home";
import Loading from "./components/common/PageLoader";
import CustomeRoute from "./components/common/CustomRoute";
import NotFound from "./components/elements/notFound";
import NotFoundSpecial from "./components/elements/NotFoundSpecial";
import { Toaster } from "react-hot-toast";
const AboutUs = lazy(() => import("./components/pages/AboutUs/index"));
const Careers = lazy(() => import("./components/pages/Careers/index"));
const Engineering = lazy(() => import("./components/pages/Engineering"));
const Operations = lazy(() => import("./components/pages/Operations"));
const Sales = lazy(() => import("./components/pages/Sales"));
const Miscellaneous = lazy(() => import("./components/pages/Miscellaneous"));
const JobApply = lazy(() => import("./components/pages/JobApply"));
const SuccessJobs = lazy(() => import("./components/pages/JobSuccess"));
//const ComingSoon = lazy(() => import("./components/pages/ComingSoon/ComingSoon"));
// const MandiCropInfo = lazy(() => import("./components/pages/MandiCropInfo"));
// const InspectionInfo = lazy(() => import("./components/pages/InspectionInfo"));
// const SigningDocument = lazy(() => import("./components/pages/SigningDocument"));
// const ProtectedRoutes = lazy(() => import("./protected/ProtectedRoutes"));
// const Buyer = lazy(() => import("./components/pages/Buyer/Buyer"));
// const KisanKopia = lazy(() => import("./components/pages/KisanKopia/landingpage/index"));
// const FarmerListing = lazy(() => import("./components/pages/KisanKopia/farmerListing"));
const FarmerPage = lazy(() => import("./components/pages/farmerpage/index"));
// const PromotionalPost = lazy(() => import("./components/pages/PromotionalPost"));
//const SupplyChain = lazy(() =>  import("./components/pages/ComingSoon/ComingSoon"));
// const VillageprofileEdit = lazy(() => import("./components/pages/MGVillageAdmin/index")); 
// const FeedRoute = lazy(() => import("./components/pages/MeraGaon/Feed"));
// const ENamCreateBid = lazy(() => import("./components/pages/E-Nam/CreateBid"));
// const ENamBidList = lazy(() => import("./components/pages/E-Nam/BidLists"));
// const ENamSellerInfo = lazy(() => import("./components/pages/E-Nam/SellerInfo"));
// const BidsCreated = lazy(() => import("./components/pages/E-Nam/CreatedBids"));
// const ApnonSepoochoAskQuestion = lazy(() => import("./components/pages/ApnonSePoochoAskQuestion/index"));
// const ENamLogin = lazy(() => import("./components/pages/E-Nam/Login"));
// const AdvanceSupply = lazy(() => import("./components/pages/E-Nam/AdvanceSupplies"));
// const AdvanceDemand = lazy(() => import("./components/pages/E-Nam/AdvanceDemand/index"));
// const WinnerDetails = lazy(() => import("./components/pages/E-Nam/WinnerDetails"));
const App = () => {
    return (
        <div>
            <Toaster position="bottom-center" containerClassName="toaster-container" />
            <BrowserRouter>
                <Suspense fallback={<Loading />}>
                    <div>
                        <Switch>
                            {/* <Route exact path="/public/:trackingID" component={MandiCropInfo} /> */}
                            {/* <Route exact path="/public/inspection/:trackingID" component={InspectionInfo} /> */}
                            {/* <Route exact path="/public/document-sign/:id" component={SigningDocument} /> */}
                            <CustomeRoute exact path="/" component={Home} />
                            <CustomeRoute exact path="/about" component={AboutUs} />
                            <CustomeRoute exact path="/careers" component={Careers} />
                            <CustomeRoute exact path="/careers/engineering" component={Engineering} />
                            <CustomeRoute exact path="/careers/operations" component={Operations} />
                            <CustomeRoute exact path="/careers/sales" component={Sales} />
                            <CustomeRoute exact path="/careers/miscellaneous" component={Miscellaneous} />
                            <CustomeRoute exact path="/success/jobs" component={SuccessJobs} />
                            <CustomeRoute exact path="/apply/:jobID" component={JobApply} />
                            {/* <CustomeRoute exact path="/buyer" component={Buyer} /> */}
                            <CustomeRoute exact path="/farmer" component={FarmerPage} />
                            {/* <CustomeRoute exact path="/supply-chain" component={SupplyChain} /> */}
                            <CustomeRoute exact path="/not-found" component={NotFoundSpecial} />
                            {/* <CustomeRoute exact path="/farmers/Kopiafarmers" component={KisanKopia} /> */}
                            {/* <CustomeRoute exact path="/asp/ask-question" component={ApnonSepoochoAskQuestion} /> */}
                            {/* <CustomeRoute
                                exact
                                path="/asp/ask-question/:questionlanguage"
                                component={ApnonSepoochoAskQuestion}
                            /> */}
                            {/* <CustomeRoute exact path="/village/profile/edit" component={VillageprofileEdit} /> */}
                            {/* <CustomeRoute
                                exact
                                path="/farmers/kopiaFarmers/listings/:infraId"
                                component={FarmerListing}
                            />
                            <CustomeRoute exact path="/promotion/:hash" component={PromotionalPost} />
                            <Route path="/protected" component={ProtectedRoutes} />
                            <CustomeRoute path="/meragaon/feed" component={FeedRoute} />
                            <CustomeRoute path="/enam/bid/create/:lotid/:sellerid" component={ENamCreateBid} />
                            <CustomeRoute exact path="/enam/bids" component={ENamBidList} />
                            <CustomeRoute exact path="/enam/login" component={ENamLogin} />
                            <CustomeRoute
                                exact
                                path="/enam/bid/sellerinfo/:lotid/:sellerid"
                                component={ENamSellerInfo}
                            />
                            <CustomeRoute exact path="/enam/bids/created" component={BidsCreated} />
                            <CustomeRoute exact path="/enam/advance-supply" component={AdvanceSupply} /> 
                            <CustomeRoute exact path="/enam/advance-demand" component={AdvanceDemand} />
                            <CustomeRoute exact path="/enam/bid/winner-details/:lotId/:buyerId" component={WinnerDetails} /> */}
                            <CustomeRoute component={NotFound} />
                        </Switch>
                    </div>
                </Suspense>
            </BrowserRouter>
        </div>
    );
};
export default App;
