import candidateTypes from "../../actions/Candidate/types";
export function event(state={}, action){
    if(action.type===candidateTypes.FETCH_CANDIDATE_EVENT_DATA){
        return action.payload
    }
    return state;
}

export function linkRef(state="", action){
    if(action.type===candidateTypes.FETCH_CANDIDATE_EVENT_DATA){
        return action.linkRef
    }
    return state;
}

export function documentList(state =[], action){
    if(action.type===candidateTypes.FETCH_DOCUMENT_TYPES){
        return action.payload;
    }
    return state;
}

export function candidateLocation(state = {lat: null, lon: null}, action){
    if(action.type===candidateTypes.SET_CANDIDATE_LOCATION){
        return action.payload;
    }
    return state;
}

export function uploadedFiles(state =[], action){
    if(action.type===candidateTypes.STORE_UPLOADED_FILE){
        return [...state, action.payload];
    }
    return state;
}
