import React, { Component } from 'react'
import farmer_img_1 from '../../../../images/farmer.jpg';
import farmer_img_2 from '../../../../images/farmer3.png';
import farmer_img_3 from '../../../../images/farmer2.png';
import buyer_img_1 from '../../../../images/buyer.png';
import SectionContainer from '../../../common/SectionContainer'
import quoteIcon from '../../../../images/quote.png';
import DoublyCicularLinkedList from '../../../../utils/DoublyCircularLinkedList' //for carousdhskle 
var TESTIMONIALS =[
    {
        id:0,
      image: farmer_img_1,
      name: 'Narendra Patidar (Indore, Madhya Pradesh), Farmer',
      description:
        'I like Kisan Network as it helps me get better prices for my crops as compared to other local buyers and Mandi rates. Since the produce is taken from the field, I am also able to save on transportation costs. As it benefits me and other farmers, we will continue to use it to sell our produce.'
    },
    {
        id:1,
      image: farmer_img_2,
      name: 'Rangadu (Kurnool, Andhra Pradesh), Farmer',
      description:
        'Benefits of selling on Kisan Network - “We are really very happy to say that Kisan Network was helpful to purchase on a single day and giving money with in one day. Actually one trader asked my onions for Rs.1000, but through Kisan Network I sold at Rs.15/Kg. I am very interested to sell through Kisan Network this summer season as well. Thanks to Kisan Network that they have come here.” (Translated from Telugu)'
    },
    {
        id:2,
      image: buyer_img_1,
      name: 'M. Arun Kumar, Buyer',
      description:
        'I earlier used to buy from the local Mandi. But with Kisan Network, I not only get potatoes at reasonable rates, but also am assured of consistently good quality. The best thing is that I save time by not going to the Mandi and can depend on their services. I will keep purchasing from Kisan Network in the future too.(Translated from Hindi)'
    },
    {
        id:3,
      image: farmer_img_3,
      name: 'Balachandrudu (Kurnool, Andhra Pradesh), Farmer',
      description:
        'Problems of selling in Mandi - “We are facing many problems at Kurnool market mandi. We have to wait for 3 to 4 days and after this they purchase our onions. They do not give money on the same day. First they give 10,000 rupees advance and after 10 days, they pay the balance. We are also charged for “hamali” and the weighing cost.” (Translated from Telugu)'
    }
  ]
class Testimonial extends Component{
    constructor(props){
        super(props) 
        this.state ={
            activeNode:null,
            play: false,
            intervalID:null,
            timeOutID:null
        }
        this.currentRef = React.createRef();
        this.prevRef = React.createRef();
        this.nextRef  = React.createRef();
    }
    componentDidMount(){
        /**
         * Initialized a Circular Doubly Linked List 
         */
        let DoublyCicularLinkedListObj  = new DoublyCicularLinkedList();
        let arrayLinkedList = DoublyCicularLinkedListObj.arrayToLinkedList(TESTIMONIALS); 
        let activeNode = arrayLinkedList[1]; //randomly selected a node to display in the card, based on this, now we can show prev and next testimonials, YO.
        let timeOutID = setTimeout(()=>{
            let intervalID= setInterval(this.changeCard, 10000);
            this.setState({intervalID: intervalID})
        } ,5000)
        this.setState({activeNode:activeNode, timeOutID: timeOutID});

    }

    handleChangeBtnClick = (e)=>{
        let btnType = e.target.dataset.type; //next or prev
        let currentNode = this.state.activeNode;
        let newActiveNode =null;
        if(btnType==='prev'){
            newActiveNode = currentNode.prev;
        }
        else{
            newActiveNode = currentNode.next
        }
        this.changeCard(newActiveNode) 
    }
    changeCard = (newActiveNode=this.state.activeNode.next)=>{
        this.currentRef.current.classList.add('animate-card');
        this.prevRef.current.classList.add('animate-card');
        this.nextRef.current.classList.add('animate-card');
        this.setState({activeNode: newActiveNode,play: true})
        setTimeout(()=>{
            this.currentRef.current.classList.remove('animate-card');
            this.prevRef.current.classList.remove('animate-card');
            this.nextRef.current.classList.remove('animate-card');
        }, 600) ;
    }
    componentWillUnmount(){
        clearInterval(this.state.intervalID);
        clearTimeout(this.state.timeOutID)
    }
    render(){

        let {activeNode} = this.state
        if(!activeNode){
            return null;
        }
        let prevNode = activeNode.prev;
        let nextNode = activeNode.next;

        return(
           <SectionContainer   id="section3">
                <div className="testimonial-section full-width" >
                    <div className="text-center">
                        <h1 className="heading-secondary heading-special">Customer Stories</h1>
                    </div>
                    <div className="background--1" />
                    <div className="testimonial-wrapper">
                        <div className="testimonial-container">
                            <div key="first-card"  className="first-card  " ref={this.prevRef}>
                                <img className="quoteIcon" src={quoteIcon} alt="quoteIcon"/>
                                <p className="text-sm testimonial-text"><i>{prevNode.data.description}</i></p>
                                <div className="info-wrapper">
                                    <div className="image">
                                        <img src={prevNode.data.image} alt=""/>
                                    </div>
                                    <div className="name">
                                        <p className="text-sm" >{prevNode.data.name}</p>
                                    </div>
                                </div>
                            </div>                         
                            <div className="second-card active-card animate-in" ref = {this.currentRef}>
                               <div>
                               <img className="quoteIcon" src={quoteIcon} alt="quoteIcon"/>
                                    <p className="text-sm testimonial-text"> <i>{activeNode.data.description}</i> </p>
                               </div>
                                    <div className="info-wrapper">
                                        <div className="image">
                                            <img src={activeNode.data.image} alt=""/>
                                        </div>
                                        <div className="name">
                                            <h5>{activeNode.data.name}</h5>
                                        </div>
                                    </div>
                            </div>
                            <div className="third-card" ref={this.nextRef}>
                                <img className="quoteIcon" src={quoteIcon} alt="quoteIcon"/>
                                <p className="text-sm testimonial-text"> <i>{nextNode.data.description}</i> </p>
                                <div className="info-wrapper">
                                    <div className="image">
                                        <img src={nextNode.data.image} alt=""/>
                                    </div>
                                    <div className="name">
                                        <p className="text-sm" >{nextNode.data.name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-btns">
                            <div className='wrapper' >
                                <div onClick={this.handleChangeBtnClick} data-type='prev' className="arrow-btn">
                                    <i className="fa fa-chevron-left" data-type='prev' ></i>
                                </div>
                                <div onClick={this.handleChangeBtnClick} data-type='next' className="arrow-btn">
                                    <i className="fa fa-chevron-right" data-type='next' ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </SectionContainer>
        )
    }
}
export default Testimonial;
