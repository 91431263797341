import React, { Component } from 'react'
import SectionContainer from '../../../common/SectionContainer'
import quoteIcon from '../../../../images/quote.png'
/* eslint-disable */
//logos
import forbes_logo from '../../../../images/forbes.jpg';
import tech_crunch_logo from '../../../../images/tc-logo.jpg';
import national_geographic_logo from '../../../../images/national-geographic.png';
import indian_express_logo from '../../../../images/new-indian-express.png';
import entrepreneur_logo from '../../../../images/entrepreneur.png';
import economics_times_logo from '../../../../images/et-logo-full.png';
import hindustan_times_logo from '../../../../images/ht-logo-full.png';
import india_today_logo from '../../../../images/it-logo-full.png';
import times_of_india_logo from '../../../../images/toi.png';
import the_hindu_logo from '../../../../images/Hindu1.png';
import yourstory_logo from '../../../../images/yourstory_logo.png';
import business_insider_logo from "../../../../images/business-Insider-Logo.png";
import techinasia_logo from "../../../../images/techinasia_logo.png";
import inc42_logo from "../../../../images/inc42_logo.png";
import tech_story_logo from "../../../../images/tech_story_logo.jpeg";

// {
//     logo:national_geographic_logo,
//     title:'Father and Son Aim to Connect Farmers Through Phones',
//     content:`Imagine you're a farmer in India with a crop of potatoes to sell. Typically, 
//     you go to a marketplace called a mandi and get the best price you can from a local middleman, who will ...
//     `,
//     link:'https://news.nationalgeographic.com/2018/02/chasing-genius-father-son-india-farmers-kisan-network/'
// },
// {
//     logo:indian_express_logo,
//     title:'Kisan Network is putting the power of purchase back in the hands of the farmer',
//     content:`Princeton dropout, AP AgTech winner, featured in the Forbes 30 under 30 list — all these are pale in comparison to Aditya Agarwalla's mission, which is to help farmers get their due in our country.
//     One of the mos..
//     `,
//     link:'https://www.edexlive.com/beinspired/2018/jan/04/kisan-network-is-putting-the-power-of-purchase-back-in-the-hands-of-the-farmer-1867.html'
// },

const NEWSARTICLES = [
    {
        logo: business_insider_logo,
        title: "Paypal founder Peter Thiel's foundation invests in an Indian fund for farmers",
        content: "Kisan Network, an Indian agriculture supply chain startup that aims to connect farmers via phones has raised $3 million ( ₹21.4 crore) as seed fund from PayPal founder...",
        link: "https://www.businessinsider.in/business/startups/news/paypal-founder-peter-thiels-foundation-invests-in-an-indian-fund-for-farmers/articleshow/73726331.cms"
    },
    {
        logo: yourstory_logo,
        title: "This agritech startup is now a tech supply chain for 55k Indian farmers",
        content: "Agritech startup Kisan Network began life as a science project at Princeton. The platform, which benefits over 50,000 farmers in 6,000 villages, has now raised $3 million funding that it will use to further expansion and launch new products and services...",
        link: "https://yourstory.com/2020/01/agritech-startup-kisan-network-indian-farmers-tech-supply-chain"
    },
    {
        logo: entrepreneur_logo,
        title: 'Y Combinator-backed Agritech Start-Up Raises Funding',
        content: `Agritech start-up Kisan Network has raised $3 million in a fresh round of funding led by Mistletoe’s Atsushi Taira, the company said on Thursday. Several others including Y Combinator, the Thiel Foundation, Venture Highway, FundersClub and Lynett Capital also participated in the round...
        `,
        link: 'https://www.entrepreneur.com/article/345685'
    },
    {
        logo: forbes_logo,
        title: 'Forbes 30 Under 30: Social Entrepreneurs',
        content: `Backed by Y Combinator, Mistletoe and the Thiel Foundation, Kisan Network is an online marketplace 
        for small-scale farmers in India. A smartphone acts as a middleman, facilitating sales between 
        the farmers and institutional buyers lowering costs on the buyers' end and 
        securing better...
        `,
        link: 'https://www.forbes.com/30-under-30-2017/social-entrepreneurs/#65d435f81332'
    },
    {
        logo: tech_crunch_logo,
        title: 'Y Combinator-backed Kisan Network is transforming the rural Indian agricultural market',
        content: `Late at night at a schoolhouse in a village in rural India, a team of young men are pitching Indian
         farmers on a mobile service offering them a new way to sell their produce. The pitch is for the newly launched,
          Y Combinator-backed business, Kisan Network...
        `,
        link: 'https://techcrunch.com/2016/03/09/y-combinator-backed-kisan-network-is-transforming-the-rural-indian-agricultural-market/'
    },
]
class NewsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        }
        this.myRef = React.createRef();
        this.logoContainer = React.createRef();
    }
    handleClick = (e) => {
        let index = parseInt(e.target.dataset.index);
        const node = this.myRef.current;
        node.classList.remove('animate-in');
        //if user clicks (in "in the news" section) on mobile device.
        this.moveLogoContainer(index)
        setTimeout(() => {
            node.classList.add('animate-in');
            this.setState({ activeIndex: index })
        }, 300);
    }
    moveLogoContainer = (index) => {
        let logoContainer = this.logoContainer.current;
        let VIEWPORT_WIDTH = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        if (index > 0 && index < 4 && VIEWPORT_WIDTH < 700) {
            //get the transform translateX value of logo conatiner
            let matrix = window.getComputedStyle(logoContainer).left; //this will return a thing called 
            //now if translateX value is ZERO it means we need to move the container to the left, to show logo number 3,4 and 
            let transformXValue = parseInt(matrix, 10); //convert value from '10px' to 10
            if (index === 1) {
                logoContainer.style.left = '0px'
            }
            else if (index === 2) {
                if (transformXValue > -150) {
                    logoContainer.style.left = '-60%'
                }
                else {
                    logoContainer.style.left = '0'
                }
            }
            else {
                if (transformXValue < -150) {
                    logoContainer.style.left = '-70%'
                }
                else {
                    logoContainer.style.left = '-50%'
                }
            }
            //VERY COOOOL
        }
    }
    loopNewsSection = () => {
        const node = this.myRef.current;
        node.classList.remove('animate-in');
        if (this.state.activeIndex == 4) {
            setTimeout(() => {
                node.classList.add('animate-in');
                this.moveLogoContainer(1)
                this.setState({ activeIndex: 0 })
            }, 400);
        } else {
            setTimeout(() => {
                node.classList.add('animate-in');
                this.moveLogoContainer(this.state.activeIndex + 1)
                this.setState(state => ({ activeIndex: state.activeIndex + 1 }))
            }, 400);
        }
    }
    componentDidMount() {
        this.startTimer();//start timer
    }
    startTimer = () => {
        //There is 500ms d 
        var intervalID;
        let timeOutId = setTimeout(() => {
            intervalID = setInterval(this.loopNewsSection, 8000);
            this.setState({ intervalID: intervalID })
        }, 500);
        this.setState({ timeOutId: timeOutId })
    }
    /**
     * stop rotation (whatever) when user hover over the news content
     */
    handleMouseEnter = () => {
        clearInterval(this.state.intervalID)
    }
    handleMouseLeave = () => {
        this.startTimer();
    }
    componentWillUnmount() {
        clearInterval(this.state.intervalID);
        clearTimeout(this.state.timeOutId)
    }
    render() {
        let { activeIndex } = this.state
        let data = NEWSARTICLES[parseInt(activeIndex)]
        return (
            <SectionContainer id="section5">
                <div className="news-section">
                    <div className="text-center">
                        <h1 className="heading-secondary heading-special">Kisan Network News</h1>
                    </div>
                    <div className="wrapper">
                        <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className="news-content" ref={this.myRef}>
                            <img className="logo" src={data.logo} alt="company logo" />
                            <img className="quote-icon" src={quoteIcon} alt="Quote icon" />
                            <h3 className="article-heading">
                                {data.title}
                            </h3>
                            <p className="text-lg article-content">
                                {data.content}
                                <a href={data.link} target="_blank" className='read-more-content' >Read more</a>
                            </p>
                        </div>
                        <div className="logo-container " ref={this.logoContainer}>
                            <div onClick={this.handleClick} data-index='0' className={activeIndex == '0' ? 'active' : ''} >
                                <img src={business_insider_logo} data-index='0' className="" alt="brand name" />
                            </div>
                            <div onClick={this.handleClick} data-index='1' className={activeIndex == '1' ? 'active' : ''}>
                                <img src={yourstory_logo} data-index='1' className="" alt="brand name" />
                            </div>
                            <div onClick={this.handleClick} data-index='2' className={activeIndex == '2' ? 'active' : ''}>
                                <img src={entrepreneur_logo} data-index='2' className="" alt="brand name" />
                            </div>
                            <div onClick={this.handleClick} data-index='3' className={activeIndex == '3' ? 'active' : ''}>
                                <img src={forbes_logo} data-index='3' className="" alt="brand name" />
                            </div>
                            <div onClick={this.handleClick} data-index='4' className={activeIndex == '4' ? 'active' : ''}>
                                <img src={tech_crunch_logo} data-index='4' className="" alt="brand name" />
                            </div>
                        </div>
                        <div className="as-seen-on-container">
                            <h4 className="text-center">
                                Also seen on
                            </h4>
                            <div className="wrapper">
                                <a target="_blank" href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/kisan-network-raises-3-million-in-seed-funding-round-led-by-mistletoe-singapore/articleshow/73807198.cms"><img src={economics_times_logo} alt="" /></a>
                                <a target="_blank" href="https://www.edexlive.com/beinspired/2018/jan/04/kisan-network-is-putting-the-power-of-purchase-back-in-the-hands-of-the-farmer-1867.html"><img src={indian_express_logo} alt=""/></a>
                                <a target="_blank" href="https://www.techinasia.com/y-combinator-alum-kisan-banks-3m"><img src={techinasia_logo} alt=""/></a>
                                <a target="_blank" href="https://inc42.com/buzz/y-combinator-backed-kisan-network-gets-seed-funding-from-mistletoe-thiel-foundation-and-others/"><img src={inc42_logo} alt=""/></a>
                                <a target="_blank" href="https://news.nationalgeographic.com/2018/02/chasing-genius-father-son-india-farmers-kisan-network"><img src={national_geographic_logo} alt=""/></a>
                            </div>
                            <div className="wrapper">
                                <a target="_blank" href="https://techstory.in/kisan-network-secured-3-mn-seed-funding-round/"><img src={tech_story_logo}/></a>
                                <img src={hindustan_times_logo} alt="" />
                                <img src={times_of_india_logo} alt="" />
                                <img src={india_today_logo} alt="" />
                                <img src={the_hindu_logo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </SectionContainer>
        )
    }
}
export default NewsSection;
