import React from 'react'
const SectionContainer = (props) => {
    return (
        <div className = "col-md-12 clearfix">
            <div className = "row clearfix">
                <div className = "container clearfix">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default SectionContainer;