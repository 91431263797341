export default function farmerListings(state = [], action) {
    switch (action.type) {
        case "GET_FARMER_AD_LISTINGS": {
            if (action.nextCursor) {
                return [...state, ...action.payload];
            }
            return action.payload;
        }
        default:
            return state;
    }
}
