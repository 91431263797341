import React from "react";
import kn_logo_full from "../../images/KNLogoFull.png";
import { NavLink } from "react-router-dom";
/* eslint-disable */
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        activeIndex: -1,
        dropdownVisible: false,
        linksData: [
            {
                name: "Home",
                hyperLink: "/",
            },
            {
                name: "Farmer",
                hyperLink: "/farmer",
            },
            {
                name: "About Us",
                hyperLink: "/about",
                className: "important-link",
            },
            {
                name: "We Are Hiring!",
                hyperLink: "/careers",
                className: "careers-hiring",
            },
        ],
        menuVis: "hid",
        menuIco: "fa fa-bars",
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.removeQueryParam = this.removeQueryParam.bind(this);
  }
  handleClick(index) {
    this.setState({
      activeIndex: index
    });
  }
  toggleMenu() {
        if (this.state.menuVis === 'vis') {
        this.setState({ menuVis: 'hid', menuIco: 'fa fa-bars' });
        } else {
        this.setState({ menuVis: 'vis', menuIco: 'fa fa-close' });
        }
    }
    handleClick(index) {
        this.setState({
            activeIndex: index,
        });
    }
    toggleMenu() {
        if (this.state.menuVis === "vis") {
            this.setState({ menuVis: "hid", menuIco: "fa fa-bars" });
        } else {
            this.setState({ menuVis: "vis", menuIco: "fa fa-close" });
        }
    }
    removeQueryParam() {
        this.setState({ menuVis: "hid", menuIco: "fa fa-bars" });
    }
    render() {
        var active = null;
        var that = this;
        var { dropdownVisible } = this.state;
        var dropdownCard = null;
        if (dropdownVisible) {
            dropdownCard = (
                <ul className="dropdown-card-list">
                    <a href="https://angel.co/kisan-network/jobs" target="_blank" rel="noopener noreferrer">
                        <li>Engineering</li>
                    </a>
                    <a
                        href="https://www.naukri.com/ids-rural-online-marketplace-jobs-careers-3470732"
                        target="_blank"
                        rel="noopener noreferrer">
                        <li>Sales</li>
                    </a>
                </ul>
            );
        } else {
            dropdownCard = null;
        }
        return (
            <div id="kn-header" className="scroll-before clearfix">
                <div className="col-md-3 col-sm-12">
                    <div className="logo-container">
                        <a href="/">
                            <img src={kn_logo_full} alt="kisan network logo" />
                        </a>
                    </div>
                    <button onClick={this.toggleMenu} className="menu-btn">
                        <i className={this.state.menuIco} />
                    </button>
                </div>
                <div className="col-md-9 col-sm-12">
                    <div className={"link-container " + this.state.menuVis}>
                        <ul className="main-list">
                            {this.state.linksData.map(function(elem, index) {
                                return (
                                    // <Link to={elem.hyperLink}>{elem.name}</Link>
                                    <li
                                        className={active}
                                        key={index}
                                        onClick={() => {
                                            that.removeQueryParam();
                                        }}>
                                        {/* <Link to={elem.hyperLink}>{elem.name}</Link> */}
                                        <NavLink
                                            className={elem.hyperLink == "/careers" ? "careers-hiring" : null}
                                            exact
                                            activeClassName="active"
                                            to={elem.hyperLink}>
                                            {elem.name}
                                        </NavLink>
                                    </li>
                                );
                            })}
                            {/* <li> */}
                            {/* <a
                href="javascript:void(0)"
                onClick={() => {
                  this.setState({
                    dropdownVisible: !this.state.dropdownVisible
                  });
                }}
              >
                <li>Jobs</li>
              </a> */}
                            {dropdownCard}
                            {/* </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
