import React, { Component } from "react";
import util from "../../utils";
import kn_logo_icon from "../../images/kisan-netowrk-logo.png";
import { removeHash } from "react-scrollable-anchor";
import { Link, NavLink } from "react-router-dom";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownVisible: false,
        };
    }
    render() {
        return (
            <div>
                <div className="col-md-12 page-footer clearfix">
                    <div className="col-md-12">
                        <img src={kn_logo_icon} alt="kisan network logo" />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Call :</b> 7289000900
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <a href="mailto:info@kisannetwork.com">
                                            <b>Email:</b> info@kisannetwork.com
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <b>Kisan Network HQ</b>
                                        <br />
                                        230, Shankar Chowk Road,
                                        <br />
                                        Udyog Vihar Phase 1, Sector 20
                                        <br />
                                        Gurugram, Haryana 122016
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe
                                    title="kisan_network_hq_map"
                                    width="400"
                                    height="200"
                                    id="gmap_canvas"
                                    src="https://maps.google.com/maps?q=kisan%20network&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-xs-6">
                        <ul>
                            <li className="bold">General</li>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <NavLink to="/buyer">Buyer</NavLink>
                            </li>
                            <li>
                                <NavLink to="/farmer">Farmer</NavLink>
                            </li>
                            <li>
                                <NavLink to="/farmers/kopiaFarmers">Kopia Farmers</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2 col-sm-2 col-xs-6">
                        <ul>
                            <li className="bold">Company</li>
                            <li>
                                <a href="/about">About Us</a>
                            </li>
                            <li onClick={removeHash}>
                                <a href="/about#core-values">Core Values</a>
                            </li>
                            <li onClick={removeHash}>
                                <a href="/about#culture">Culture</a>
                            </li>
                            <li onClick={removeHash}>
                                <a href="/about#investors">Investors</a>
                            </li>
                            <li onClick={removeHash}>
                                <a href="/about#media">Press</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-2 col-sm-2 col-xs-6">
                        <ul className="careers-footer">
                            <li className="bold">Careers</li>
                            {/* <li>
                <Link
                  target="_blank"
                  href="https://angel.co/kisan-network/jobs/"
                  rel="noopener noreferrer"
                >
                  Engineering
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  href="https://www.naukri.com/ids-rural-online-marketplace-jobs-careers-3470732"
                  rel="noopener noreferrer"
                >
                  Sales
                </Link>
              </li> */}
                            <li>
                                <Link to="/careers/engineering">Engineering</Link>
                            </li>
                            <li>
                                <Link to="/careers/sales">Sales</Link>
                            </li>
                            <li>
                                <Link to="/careers/operations">Operations</Link>
                            </li>
                            <li>
                                <Link to="/careers/miscellaneous">Miscellaneous</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <PostFooter />
            </div>
        );
    }
}

const PostFooter = () => {
    return (
        <div className="col-md-12">
            <div className="row page-post-footer">
                <p className="text-sm copyright-text">
                    Copyright <sup>&copy;</sup> {util.getCurrentYear()} Kisan Network (IDS Rural Online Marketplace
                    Private Limited). All Rights Reserved. Made in India and California
                </p>
                <span className="follow-icons-container text-lg">
                    <a href="https://www.facebook.com/kisannetwork" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-facebook-square" />
                    </a>
                    <a href="https://twitter.com/kisannetwork" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter-square" />
                    </a>
                    <a href="https://www.linkedin.com/company/kisan-network" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-linkedin-square" />
                    </a>
                </span>
            </div>
        </div>
    );
};

export default Footer;
