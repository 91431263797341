import React from 'react'
import SectionContainer from '../../../common/SectionContainer'
//icons
import integrated from '../../../../images/integration.png'
import transparency from '../../../../images/transparency.png'
import software from '../../../../images/software.png'
import stanadarized from '../../../../images/stanadarised.png'
const androidOffline = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/androidOffline.png"
const laptop = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/laptop.png"
const HomeFeatures = ()=>(
    <SectionContainer className="clearfix" id="section-pillars" >
        <div className="feature-section full-width">
            <div className="text-center">
                <h1 className="heading-secondary heading-special">Pillars of our supply chain</h1>
            </div>
            <div className="feature-wrapper">
               <div className="feature-text">
                    <div className="feature-box">
                        <div className="heading">
                            <img src={integrated} alt=""/>
                            <h4>Integrated</h4>
                        </div>
                        <p className="text-md">End-to-end on-ground supply chain management, from farm to the buyer's doorstep.</p>
                    </div>
                    <div className="feature-box">
                        <div className="heading">
                            < img src = {
                                stanadarized
                            }
                            alt = ""  />
                            <h4>Standardized</h4>
                        </div>
                        <p className="text-md">On and off-ground enabling PAN India multi region coverage.</p>
                    </div>
                    <div className="feature-box">
                        <div className="heading">
                            <img src={transparency} alt=""/>
                            <h4>Transparent</h4>
                        </div>
                        <p className="text-md">Digitally enabled to inculcate trust absent in the current disorganized system.</p>
                    </div>
                    <div className="feature-box">
                        <div className="heading">
                            <img src={software} alt=""/>
                            <h4>Technology Driven </h4>
                        </div>
                        <p className="text-md">0% use of paper with each step digitally captured.</p>
                    </div>
                </div>
                <div className="feature-image">
                    <img className='laptop-img' src={laptop} alt=""/>
                    <img className='mobile-img' src={androidOffline} alt=""/>
                </div>
            </div>
        </div>
    </SectionContainer>
)
export default HomeFeatures;
