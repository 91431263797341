import React from 'react';

import spaceship from '../../images/spaceship.svg'

export default class NotFound extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            x: 100,
            y: 100
        }
    }

    componentDidMount() {
        this.refs.container.addEventListener('mousemove', (e) => {
            this.setState({
                x:  e.clientX - 50,
                y: e.clientY - 100
            });
        })
    }

    componentWillUnmount() {
        console.log("here2");
        this.refs.container.removeEventListener('mousemove', (e) => {
            console.log("done")
        });
    }
    render() {
        return (
            <div className="not-found-container" ref="container" >
                <img alt={""} src={spaceship} className="spaceship" ref="spaceship" style={{top: this.state.y, left: this.state.x}}/>
                {/* <div className="background-5"></div> */}
                <div className="success">
                    <div className="success-message">
                        <div className="success-message-heading">
                            <h2 className="heading-primary not-found">
                            <span className="not-found-1">4</span>
                            <span className="not-found-2">0</span>
                            <span className="not-found-3">4!</span>
                            </h2>
                        </div>
                        <div className="success-message-text">
                            <p className="heading-tertiary">Please open this page in Google Chrome.</p>
                            {/* <p className="heading-tertiary">The page you are looking for is not on our server!</p> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
