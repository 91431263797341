import React, {Component} from 'react'
import SectionContainer from '../../../common/SectionContainer'
const grid_img_1 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/carousel1.jpeg";
const grid_img_2 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/AdityaAgarwallaVilalgeTraining2.jpg";
const grid_img_3 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/carousel5.jpg";
const grid_img_4 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/vision1.jpeg";
const grid_img_5 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/TruckStuckinMudDuringDispatch.jpg";
const grid_img_6 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/FarmerMeet2.jpeg";
const grid_img_7 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/FarmerMeet.jpg";
const grid_img_8 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/FarmerAppUse2.jpg";
const grid_img_9 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/FarmerAppUse.jpg";
const grid_img_10 = "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/DirectFarmProduceProcurement.jpg";
const grid_img_11= "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/background-big.jpg";
const grid_img_12= "https://storage.googleapis.com/mglogo-cdn/Kn-aboutUs/storage.jpeg";
let imagesArray = [
    grid_img_1,
    grid_img_2,
    grid_img_3,
    grid_img_6,
    grid_img_4,
    grid_img_5,
    grid_img_12,
    grid_img_9,
    grid_img_7,
    grid_img_8,
    grid_img_10,
    grid_img_11,
]
class ImageGrid extends Component{
    constructor(props){
        super(props);
        this.state={
            showModal: false,
            selectedImage :null
        }
    }
    toggelModal = (e)=>{
        let index = e.target.dataset.index;
        document.getElementsByTagName('body')[0].style.overflow='hidden'
        document.getElementsByTagName('html')[0].style.overflow='hidden'

        this.setState(state=>{
            return {
                showModal : !state.showModal,
                selectedImage:  imagesArray[index]
            }
        })
    }
    closeModal=()=>{
        document.getElementsByTagName('body')[0].style.overflow='auto'
        document.getElementsByTagName('html')[0].style.overflow='auto'

        this.setState({
            selectedImage:null,
            showModal: false
        })
    }
    render(){
        return(
            <SectionContainer  className="clearFix" id="section4"> 
                <div className="image-grid full-width">
                    <div className="wrapper ">
                        <div  >
                            <img  onClick={this.toggelModal}  data-index='0' src={grid_img_1} alt="Kisan Network field work"/>
                        </div>
                        
                        <div className="hero">
                            <img onClick={this.toggelModal}  data-index='3' src={grid_img_6} alt="Kisan Network field work"/>
                        </div>
                        <div >
                            <img onClick={this.toggelModal}  data-index='2' src={grid_img_3} alt="Kisan Network field work"/>
                        </div>
                        
                        <div >
                            <img onClick={this.toggelModal}  data-index='4' src={grid_img_4} alt="Kisan Network field work"/>
                        </div>
                        <div >
                            <img onClick={this.toggelModal}  data-index='5' src={grid_img_5} alt="Kisan Network field work"/>
                        </div>
                        <div >
                            <img onClick={this.toggelModal}  data-index='6' src={grid_img_12} alt="Kisan Network field work"/>
                        </div>
                        <div className="vertical">
                            <img  onClick={this.toggelModal} data-index='7' src={grid_img_9} alt="Kisan Network field work"/>
                        </div>
                        <div >
                            <img  onClick={this.toggelModal} data-index='8' src={grid_img_7} alt="Kisan Network field work"/>
                        </div>
                        <div >
                            <img  onClick={this.toggelModal} data-index='9' src={grid_img_8} alt="Kisan Network field work"/>
                        </div>
                        
                        <div className="vertical">
                            <img onClick={this.toggelModal}  data-index='10' src={grid_img_10} alt="Kisan Network field work"/>
                        </div> 
                        <div className="vertical">
                            <img onClick={this.toggelModal}  data-index='1' src={grid_img_2} alt="Kisan Network field work"/>
                        </div> 
                        <div >
                            <img  onClick={this.toggelModal} data-index='11' src={grid_img_11} alt="Kisan Network field work"/>
                        </div>
                    </div>
                    {
                        this.state.showModal ? <div className="image-modal">
                                <i className="fa fa-times " onClick={this.closeModal} ></i>
                                <img src={this.state.selectedImage} alt=""/>
                            </div>:null
                    }
                </div>
            </SectionContainer>
        )
    }
}

export default ImageGrid;