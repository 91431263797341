import React from 'react'
import SectionContainer from '../../../common/SectionContainer'
const Hero = () => (
    <SectionContainer className="clearfix">
        <div className="hero-section full-width ">
            <div className="hero-text-highlight">
                <h3>Our Next Chapter</h3>
                <div className="hero-content-box">
                    <h1>$3m to Grow Our PAN India Farmer Network...<a href="https://medium.com/@kisannetwork/series-seed-c45c060116e2" target="_blank" rel="noopener noreferrer">read more</a></h1>
                </div>
            </div>
            <div className="curve ">
                <div className="heading-container">
                    <h1  >
                        Tech Enabled Supply Chain for Indian Agriculture.
                    </h1>
                    <p className="text-lg hide-on-mobile" >With a fully integrated PAN India presence, empowering small and marginal farmers to sell produce directly to businesses.</p>
                    <a className='theme-btn theme-btn--green hero-btn' href='#section-pillars'>Know More</a>
                </div>
            </div>

        </div>
    </SectionContainer>
)
export default Hero;