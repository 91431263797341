import React from 'react'
import SectionContainer from '../../../common/SectionContainer'
import sourcing_img from '../../../../images/sourcing1.svg';
import sorting_img from '../../../../images/sorting1.svg';
import grading_img from '../../../../images/grading1.svg';
import packaging_img from '../../../../images/packaging1.svg';
import dispatch_img from '../../../../images/dispatch1.svg';
import delivery_img from '../../../../images/delivery1.svg';
const HowWeWorkSection = () => {
    let data = [
        {
            name: 'Sourcing',
            icon: sourcing_img,
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            className: "sourcing-step"
        },
        {
            name: 'Sorting',
            icon: sorting_img,
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            className: "sorting-step"
        },
        {
            name: 'Grading',
            icon: grading_img,
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            className: "grading-step"

        },
        {
            name: 'Packaging',
            icon: packaging_img,
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            className: "packaging-step"

        },
        {
            name: 'Dispatch',
            icon: dispatch_img,
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            className: 'dispatch-step'
        },
        {
            name: 'Delivery',
            icon: delivery_img,
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            className: 'delivery-step'
        }
    ]
    return (
        <SectionContainer className="clearfix" id="section1" >
            <div className="process-section full-width ">
                <div className="text-center">
                    <h1 className="heading-secondary heading-special">We take complete control of the supply chain</h1>
                    <p className="text-lg">Kisan Network connects Indian farmers directly to businesses across the country by taking complete control of the supply chain. Each step is captured and monitored by our tech enabled rural management portal. Our integrated services include:</p>
                </div>
                <div className="process-stepper">
                    {
                        data.map((item, i) => {
                            return <div className={`step-wrapper ${item.className} `} >
                                <div className="icon-wrapper">
                                    <img src={item.icon} alt="process icon" />
                                    <div className="step-description" >
                                        <p className="text-sm">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                                <p className="text-lg">{item.name}</p>
                            </div>
                        })
                    }
                </div>
                <div className="read-more-link text-center">
                    <a className="read-more-link" href='/supply-chain'>Read More <i className="fa fa-chevron-right"></i></a>
                </div>
            </div>
        </SectionContainer>
    )
}
export default HowWeWorkSection;

