import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

const SectionContainer = props => {
  return (
    <ScrollableAnchor id={props.id}>
      <div className="col-md-12 border-container clearfix">
        <div className="row clearfix">
          <div
            className={
              props.className
                ? 'section-container clearfix ' + props.className
                : 'section-container clearfix'
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </ScrollableAnchor>
  );
};

export default SectionContainer;
