import React from 'react'
import SectionContainer from '../../../common/SectionContainer'
import sampleImg from '../../../../images/buyerNew2.png'
import farmerIllustration from '../../../../images/theIndianFarmer.png'
//icons
import simplePayment from '../../../../images/museum.png'
import rupee from '../../../../images/price-tag.png'
import hassleFree from '../../../../images/logistic.png'
import competetivePrice from '../../../../images/competetivePrice.svg'
import qualityFocused from '../../../../images/qualityFocused.svg'
import availability from '../../../../images/calendar.png'
const CustomerLove = ()=>(
    <SectionContainer className="clearfix" id={'section2'}>
        <div className="customer-section full-width ">
            <div className="farmer-section">
                <div className="text-center">
                    <h1 className="heading-secondary heading-special">
                    Improving farmers' crop selling experience
                    </h1>
                </div>
                <div className="wrapper ">
                    <div className="reason-wrapper">
                        <div className="reason-text">
                            <div className="icon-wrapper">
                                <img src={rupee} alt="icon"/>
                            </div>
                            <div className="heading">
                                <h4>Transparent Pricing</h4>
                                <p className="text-md">Building trust with quality driven competitive
                                prices factoring in all associated costs and expenses.</p>
                            </div>
                        </div> 
                        <div className="reason-text">
                            <div className="icon-wrapper">
                                < img src = {
                                    simplePayment
                                }
                                alt = "icon" />
                            </div>
                            <div className="heading">
                                <h4>Simple Payment</h4>
                                <p className="text-md">Direct to bank account. On Time. No installments.</p>
                            </div>
                        </div>
                        <div className="reason-text">
                            <div className="icon-wrapper">
                                <img src={hassleFree} alt="icon"/>
                            </div>
                            <div className="heading">
                                <h4>Hassle Free</h4>
                                <p className="text-md">Local logistics support with direct farm sourcing.</p>
                            </div>
                        </div> 
                    </div>
                    <div className="illustration-wrapper">
                        <img style={{filter:"grayscale(100%)"}} src={farmerIllustration} alt="illustration"/>
                    </div>
                </div>
                <div className="read-more-link text-center">
                    <a className="read-more-link" href='/farmer'>Read More <i  className="fa fa-chevron-right"></i></a>
                </div>
            </div>
            {/* buyer section starts here */}
            <div className="buyer-section">
                <div className="text-center">
                    <h1 className="heading-secondary heading-special ">
                        Assuring buyers of consistency in supply
                    </h1>
                </div>
                <div className="wrapper ">
                    <div className="reason-wrapper">
                        <div className="reason-text">
                            <div className="icon-wrapper">
                                < img src = {
                                    competetivePrice
                                }
                                alt = "icon" />
                            </div>
                            <div className="heading">
                                < h4> Competitive Pricing </h4>
                                < p className = "text-md" > Direct supply from thousands of farmers with no intermediaries. </p>
                            </div>
                        </div> 
                        <div className="reason-text">
                            <div className="icon-wrapper">
                                < img src = {
                                    qualityFocused
                                }
                                alt = "icon" />
                            </div>
                            <div className="heading">
                                < h4> Quality Focus </h4>
                                < p className = "text-md" > Throughout the supply chain from procurement to delivery, digitally assessed. </p>
                            </div>
                        </div>
                        <div className="reason-text">
                            <div className="icon-wrapper">
                                < img src = {
                                    availability
                                }
                                alt = "icon" />
                            </div>
                            <div className="heading">
                                < h4 > Consistent Availability </h4>
                                < p className = "text-md" > 365 days a year with value added services such as quality assessment, sampling etc. </p>
                            </div>
                        </div> 
                    </div>
                    <div className="illustration-wrapper">
                        <img style={{filter:"grayscale(100%)"}}  src={sampleImg} alt="illustration"/>
                    </div>
                </div>
                <div className="read-more-link text-center">
                    <a className="read-more-link" href='/buyer'>Read More <i  className="fa fa-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </SectionContainer>
)
export default CustomerLove;