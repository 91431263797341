import React from "react";
import SectionContainer from "../../../common/SectionContainer";
import globe from "../../../../images/globe.png";
//import icons
import location from "../../../../images/regions.png";
import farmerIcon from "../../../../images/farmericon.png";
import distance from "../../../../images/distance.png";
const HomepageNumbers = () => {
    return (
        <SectionContainer>
            <div className="homepage-numbers full-width ">
                <div className="number-wrapper">
                    <div>
                        <img src={globe} alt="" />
                        <h3 className="text-md">Regions Covered</h3>
                        <h1 className="number heading-primary">15+</h1>
                    </div>
                    <div>
                        <img src={farmerIcon} alt="" />
                        <h3 className="text-md">Farmers</h3>
                        <h1 className="number heading-primary">80,000+</h1>
                    </div>
                    <div>
                        <img src={location} alt="" />
                        <h3 className="text-md">Villages Covered</h3>
                        <h1 className="number heading-primary">10,000+</h1>
                    </div>
                    <div>
                        <img src={distance} alt="" />
                        <h3 className="text-sm">
                            Distance Travelled <small>(km)</small>{" "}
                        </h3>
                        <h1 className="number heading-primary">375,000+</h1>
                    </div>
                </div>
            </div>
        </SectionContainer>
    );
};
export default HomepageNumbers;
