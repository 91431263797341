import React from "react";

function InlineLoader(props){
    return(
        <div className="spinny-loader">
        </div>
    )
}
export default InlineLoader;

