/**
 * Component for Homepage
 * @author Gulam Hussain, 16 April 2019
 */

import React from 'react'
import Container from '../../common/Container'
import Hero from './components/Hero';
import HomepageNumbers from './components/homePageNumbers'
import HomeFeatures from './components/HomeFeatures'
import HowWeWorkSection from './components/howWeWork'
import CustomerLove from './components/WhyOurCustomers'
import Testimonial from './components/TestimonialNew'
import NewsSection from './components/NewsSection'
import ImageGrid from './components/imageGridNew'
const Homepage = ()=>(
    <Container>
        <div className="homepage">
            <Hero/>
            <HomepageNumbers/>
            <HomeFeatures/>
            <HowWeWorkSection/>
            <CustomerLove/>
            <Testimonial/>
            <NewsSection/>
            <ImageGrid/>
        </div>
    </Container>
)
export default Homepage;
