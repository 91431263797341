/* eslint-disable */
import React from 'react'
import {Route} from 'react-router-dom'
import Aux from './Auxillary'
import Header from '../elements/Header'
import Footer from '../elements/Footer'
const CustomeRoute = ({component:Component, ...rest})=>{
    return(
        <Route {...rest} render = {
            (props)=>{
                return (
                    <Aux>
                        <Header/>
                        <Component {...rest} />
                        <Footer/>
                    </Aux>
                )
            }
        } />
    )
}
export default CustomeRoute;
