import React from "react";
import InlineLoader from "./inline-loader";
function PageLoader(props){
    return (
            <div className="page-loader">
                <div className="overlay" />
                <div className="loader">

                </div>
                <div className="content" >
                    <InlineLoader/>
                </div>
            </div>
        )
}

export default PageLoader;
